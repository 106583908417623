import { Injectable } from '@angular/core';
import { SERVICE_REST } from '../commons/app-utils';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PermisoService {

  private permisoUrl = SERVICE_REST + 'permiso';
  constructor(private http: HttpClient) {}

  getPermiso(): Observable<any> {
    return this.http.get(this.permisoUrl);
  }

  getPermisoPorId(id: number): Observable<any> {
    return this.http.get(this.permisoUrl + '/' + id);
  }

  getPermisoByNombre(nombre: string): Observable<any> {
    let params = 'nombre=' + nombre;
    return this.http.get(this.permisoUrl + '/byName?' + params);
  }

  agregarPermiso(permiso: any): Observable<any> {
    return this.http.post(this.permisoUrl, permiso);
  }

  modificarPermiso(permiso: any): Observable<any> {
    return this.http.put(this.permisoUrl, permiso);
  }

  eliminarPermiso(permisoId: number): Observable<any> {
    return this.http.delete(this.permisoUrl + '/' + permisoId);
  }

}

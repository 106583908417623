import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVICE_REST } from '../commons/app-utils';
import { Observable } from 'rxjs';
import { PaginationService } from './pagination.service';

@Injectable({
  providedIn: 'root',
})
export class EmpresaService extends PaginationService {

  constructor(http: HttpClient) {
    super(http, SERVICE_REST + 'empresa');
  }


  getEmpresaPorId(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id);
  }

  agregarEmpresa(empresa: any): Observable<any> {
    return this.http.post(this.url, empresa);
  }

  modificarEmpresa(empresa: any): Observable<any> {
    return this.http.put(this.url, empresa);
  }

  eliminarEmpresa(empresaId: number): Observable<any> {
    return this.http.delete(this.url + '/' + empresaId);
  }
}

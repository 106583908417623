import { Sucursal } from "./sucursal";

export class Producto {
    productoId: number;
    nombreProducto: string;
    descripcion: string;
    precioCompra: number;
    precioVenta: number;
    stockMinimo: number;
    stockActual: number;
    codigoBarra: string;
    sucursalId: Sucursal;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVICE_REST } from '../commons/app-utils';
import { Observable } from 'rxjs';
import {PaginationService} from './pagination.service';
import {Pagination} from '../models/pagination';

@Injectable({
  providedIn: 'root',
})
export class DescuentoService extends  PaginationService{
  constructor(http: HttpClient) {
    super(http, SERVICE_REST + 'descuento');
  }
  getDescuento() {
    return this.getAllPagination(new Pagination(1, 0, null, null, null));
  }
  getDescuentoPorId(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id);
  }

  agregarDescuento(descuento: any): Observable<any> {
    return this.http.post(this.url, descuento);
  }

  modificarDescuento(descuento: any): Observable<any> {
    return this.http.put(this.url, descuento);
  }

  eliminarDescuento(descuentoId: number): Observable<any> {
    return this.http.delete(this.url + '/' + descuentoId);
  }
}

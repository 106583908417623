import { Usuario } from "./usuario";

export class Vendedor {
    vendedorId: number;
    nombre: string;
    apellido: string;
    numeroDocumento: string;
    usuarioId: {
        nombre: string;
        apellido: string;
        usuarioId: number;
        userName: string;
    };
}
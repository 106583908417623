import {Factura} from './factura';
import {Producto} from './producto';
import {isANumber} from '../commons/number-validator';

export class FacturaDetalle {
    facturaDetalleId: number;
    facturaId: Factura;
    productoId: Producto;
    codigoBarra: string;
    nombreProducto: string;
    cantidad: number;
    precioVenta: number;
    total: number;
}

export class FacturaDetalleFilter {
    facturaId?: FacturaDetalleFacturaId;

    constructor(facturaId?: FacturaDetalleFacturaId) {
        this.facturaId = facturaId;
    }
}

export class FacturaDetalleFacturaId {
    private readonly facturaId: number;
    constructor(facturaId: number) {
        this.facturaId = facturaId;
    }

    id() {
        return this.facturaId;
    }

    isValid(): boolean {
        return isANumber(this.facturaId);
    }
}

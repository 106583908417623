export class Empresa {
    empresaId: number;
    razonSocial: string;
    fechaConstitucion: Date;
    rubro: string;
    nombre: string;
    telefono: string;
    correo: string;
    timbrado: string;
    fechaInicio: Date;
    fechaFin: Date;
    ruc: string;
    logo: string;
}

export class Deuda {
  deudaId: number;
  montoTotal: number;
  saldo: number;
  descripcion: string;
  cantidadCuotas: number;
  periodo: string;
  clienteId: {
    clienteId: number;
    nombre: string;
    apellido: string;
    numeroDocumento: string;
  };
  servicioId: {
    servicioId: number;
    nombre: string;
    descripcion: string;
    sucursalId: {
      sucursalId: number;
      fechaApertura: number;
      nombre: string;
      telefono: number;
      correo: string;
      empresaId: {
        empresaId: number;
        razonSocial: string;
        fechaConstitucion: number;
        rubro: string;
        nombre: string;
        telefono: number;
        correo: string;
      };
    };
  };
  descuentoId: {
    descuentoId: number;
    porcentaje: number;
    concepto: string;
    descripcion: string;
  };
}

import { Deuda } from "./deuda";
import { DeudaEstado } from "./deuda-estado";
import { isANumber } from '../commons/number-validator';

export class DeudaDetalle {
    deudaDetalleId: number;
    montoCuota: number;
    montoMora: number;
    numeroCuota: number;
    fechaVencimiento: Date;
    deudaId: Deuda;
    deudaEstadoId: DeudaEstado;
}

export class DeudaDetalleFilter {
    deudaId?: DeudaDetalleDeudaId;

    constructor(deudaId?: DeudaDetalleDeudaId) {
        this.deudaId = deudaId;
    }
}

export class DeudaDetalleDeudaId {
    private readonly deudaId: number;
    constructor(deudaId: number) {
        this.deudaId = deudaId;
    }

    id() {
        return this.deudaId;
    }

    isValid(): boolean {
        return isANumber(this.deudaId);
    }
}
import { Injectable } from '@angular/core';
import { SERVICE_REST } from '../commons/app-utils';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationService } from './pagination.service';

@Injectable({
  providedIn: 'root',
})
export class ClienteService extends PaginationService {

  constructor(http: HttpClient) {
    super(http, SERVICE_REST + 'cliente');
  }


  getClientePorId(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id);
  }

  getClienteByNumeroDocumento(numeroDocumento: string): Observable<any> {
    const params = 'numeroDocumento=' + numeroDocumento;
    return this.http.get(this.url + '/venta?' + params);
  }

  agregarCliente(cliente: any): Observable<any> {
    return this.http.post(this.url, cliente);
  }

  modificarCliente(cliente: any): Observable<any> {
    return this.http.put(this.url, cliente);
  }

  eliminarCliente(clienteId: number): Observable<any> {
    return this.http.delete(this.url + '/' + clienteId);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVICE_REST } from '../commons/app-utils';
import { Observable } from 'rxjs';
import { PaginationService } from './pagination.service';
import {Filter} from '../models/filter';
import {AuthService} from './auth.service';
import {Pagination} from '../models/pagination';

@Injectable({
  providedIn: 'root',
})
export class ProductoService extends PaginationService {

  constructor(http: HttpClient, private authService: AuthService) {
    super(http, SERVICE_REST + 'producto');
  }

  getProducto(): Observable<any> {
      const filter: Filter[] = [];
      if ( this.authService.currentUser.sucursales !== undefined &&  this.authService.currentUser.sucursales.length > 0 ) {
        filter.push({
          key : 'sucursalId', operation : ':',
          value : this.authService.currentUser.sucursales.map(s => s.sucursalId), joinFields : ['sucursalId']});
      }
    return this.getAllPagination(new Pagination(1, 0, '', '', JSON.stringify(filter)));
  }
  getProductoPorId(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id);
  }

  getAllProductoPorNombre(data: string): Observable<any> {
    return this.http.get(this.url + '/byName/' + data);
  }

  getProductoByCodigoBarra(codigoBarra: string): Observable<any> {
    let params = 'codigoBarra=' + codigoBarra;
    return this.http.get(this.url + '/venta?' + params);
  }

  agregarProducto(producto: any): Observable<any> {
    return this.http.post(this.url, producto);
  }

  modificarProducto(producto: any): Observable<any> {
    return this.http.put(this.url, producto);
  }

  eliminarProducto(productoId: number): Observable<any> {
    return this.http.delete(this.url + '/' + productoId);
  }
}

import {Injectable} from '@angular/core';
import {SERVICE_REST} from '../commons/app-utils';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FacturaDetalle} from '../models/factura-detalle';
import {PaginationService} from './pagination.service';

@Injectable({
    providedIn: 'root'
})
export class FacturaDetalleService extends PaginationService {


    constructor(http: HttpClient) {
        super(http, SERVICE_REST + 'factura_detalle');
    }

    getFacturaDetalle(): Observable<any> {
        return this.http.get(this.url);
    }

    getFacturaDetallePorId(id: number): Observable<any> {
        return this.http.get(this.url + '/' + id);
    }

    getFacturaDetallePorFactura(id: number): Observable<any> {
        return this.http.get(this.url + '/byFac/' + id);
    }

    addListaDetalle(listDetalle: Array<FacturaDetalle>, facturaId: any): Observable<any> {
        return this.http.post(this.url + '/factura/' + facturaId, listDetalle);
    }

    agregarFacturaDetalle(facturaDetalle: any): Observable<any> {
        return this.http.post(this.url, facturaDetalle);
    }

    modificarFacturaDetalle(facturaDetalle: any): Observable<any> {
        return this.http.put(this.url, facturaDetalle);
    }
}

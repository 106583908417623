import { Injectable } from '@angular/core';
import { SERVICE_REST } from '../commons/app-utils';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {PaginationService} from './pagination.service';

//import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {Pagination} from '../models/pagination';

declare var qz: any;

@Injectable({
  providedIn: 'root',
})
export class ServicioService extends PaginationService {
  constructor(http: HttpClient) {
    super(http, SERVICE_REST + 'servicio');
  }
  getServicio(): Observable<any> {
    return this.getAllPagination(new Pagination(1, 0, null, null, null));
  }

  getServicioPorId(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id);
  }

  agregarServicio(servicio: any): Observable<any> {
    return this.http.post(this.url, servicio);
  }

  modificarServicio(servicio: any): Observable<any> {
    return this.http.put(this.url, servicio);
  }

  eliminarServicio(servicioId: number): Observable<any> {
    return this.http.delete(this.url + '/' + servicioId);
  }
  // Print data to chosen printer
  
  printData(printer: string, data: any): Observable<any> {
  const config = qz.configs.create(printer);  
  return Observable.fromPromise(qz.print(config, data))
    .map((anything: any) => anything);
    //.catch(this.errorHandler);
}
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SERVICE_REST } from '../commons/app-utils';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  private rolUrl = SERVICE_REST + 'rol';
  constructor(private http: HttpClient) {}

  getRol(): Observable<any> {
    return this.http.get(this.rolUrl);
  }

  getRolPorId(id: number): Observable<any> {
    return this.http.get(this.rolUrl + '/' + id);
  }

  getRolByNombre(nombre: string): Observable<any> {
    let params = 'nombre=' + nombre;
    return this.http.get(this.rolUrl + '/byName?' + params);
  }

  agregarRol(rol: any): Observable<any> {
    return this.http.post(this.rolUrl, rol);
  }

  asignarPermiso(rol: any,): Observable<any> {
    return this.http.put(this.rolUrl + '/permiso/' + rol.rolId, rol.permisoId);
  }

  modificarRol(rol: any): Observable<any> {
    return this.http.put(this.rolUrl, rol);
  }

  eliminarRol(rolId: number): Observable<any> {
    return this.http.delete(this.rolUrl + '/' + rolId);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SERVICE_REST } from '../commons/app-utils';
import { PaginationService } from './pagination.service';
import {AuthService} from './auth.service';
import {Filter} from '../models/filter';
import {Pagination} from '../models/pagination';

@Injectable({
  providedIn: 'root',
})
export class SucursalService extends PaginationService {
  constructor(http: HttpClient, private authService: AuthService) {
    super(http, SERVICE_REST + 'sucursal');
  }
  getSucursal(): Observable<any> {
    const filter: Filter[] = [];
    if ( this.authService.currentUser.sucursales !== undefined &&  this.authService.currentUser.sucursales.length > 0 ) {
      filter.push({
        key : 'sucursalId', operation : ':',
        value : this.authService.currentUser.sucursales.map(s => s.sucursalId), joinFields : []});
    }
    return this.getAllPagination(new Pagination(1, 0, null, null, JSON.stringify(filter)));
  }

  getSucursalPorId(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id);
  }

  getSucursalPorEmpresa(id: number): Observable<any> {
    return this.http.get(this.url + '/byEmp/' + id);
  }

  agregarSucursal(sucursal: any): Observable<any> {
    return this.http.post(this.url, sucursal);
  }

  modificarSucursal(sucursal: any): Observable<any> {
    return this.http.put(this.url, sucursal);
  }

  eliminarSucursal(sucursalId: number): Observable<any> {
    return this.http.delete(this.url + '/' + sucursalId);
  }

  asignarEmpresa(sucursal: any): Observable<any> {
    return this.http.put(this.url + '/empresa/' + sucursal.sucursalId, sucursal.empresaId);
  }
}

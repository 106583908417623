import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SERVICE_REST } from '../commons/app-utils';
import { DeudaDetalle } from '../models/deuda-detalle';

@Injectable({
  providedIn: 'root'
})
export class DeudaDetalleService {

  private deudaDetalleUrl = SERVICE_REST + 'deuda_detalle';
  constructor(private http: HttpClient) {}

  getDeudaDetalle(): Observable<any> {
    return this.http.get(this.deudaDetalleUrl);
  }

  getDeudaDetallePorId(id: number): Observable<any> {
    return this.http.get(this.deudaDetalleUrl + '/' + id);
  }

  getDeudaDetallePorDeuda(deudaId: number): Observable<any> {
    return this.http.get(this.deudaDetalleUrl + '/byDeuda/' + deudaId);
  }

  addListaDetalle(listDetalle: Array<DeudaDetalle>, deudaId: any): Observable<any> {
    return this.http.post(this.deudaDetalleUrl + '/byDeuda/' + deudaId, listDetalle);
}
  
  agregarDeudaDetalle(deuda: any): Observable<any> {
    return this.http.post(this.deudaDetalleUrl, deuda);
  }

  modificarDeudaDetalle(listDetalle: Array<DeudaDetalle>): Observable<any> {
    return this.http.put(this.deudaDetalleUrl, listDetalle);
  }
}

import {Sucursal} from './sucursal';

export class Usuario {
  usuarioId: number;
  nombre: string;
  apellido: string;
  telefono: string;
  correo: string;
  userName: string;
  password: string;
  roles: {
    rolId: number;
    nombre: string;
    descripcion: string;
    permisos: {
      permisoId: number;
      nombre: string;
      descripcion: string;
    }
  };
  sucursales: Sucursal[];
  activo: string;
  token?: string;
}
